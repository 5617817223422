// stores/orderOneStore.ts
import { defineStore } from 'pinia';
import { OrderDTO, OrderExtend } from "@/dto/order/OrderDTO";
import { ClassUrl } from '~/class/url/ClassUrl';
import { watch } from 'vue';
import { BuyerOrganizerDTO } from '~/dto/BuyerOrganizerDTO';

export const useOrderOneStore = defineStore('orderOneStore', {
  state: () => ({
    order: new OrderExtend(),
    buyerOrganizer:  new BuyerOrganizerDTO(),
  }),
  actions: {
    async fetchOrder(data: OrderExtend) {
      this.order = new OrderExtend(data);
      this.buyerOrganizer = new BuyerOrganizerDTO(data.buyerOrganizer);
    },
    setOrder(data: OrderExtend) {
      this.order = new OrderExtend(data);
      this.buyerOrganizer = new BuyerOrganizerDTO(data.buyerOrganizer);
    },
    persiste: true,
  },
});


export function initializeOrderStore() {

  const store = useOrderOneStore();
  const route = useRoute();
  const currentOrderId = ClassUrl.getUrlParams("orderId");

  if (!route.fullPath.includes('order-id') || store.order.id.toString() === currentOrderId) return;

  watch(
    () => route.fullPath,
     async () => await loadOrder(store),
    { immediate: true }
  );
}

async function loadOrder(store: ReturnType<typeof useOrderOneStore>) {
  const orderId = ClassUrl.getUrlParams("orderId");
  if (!orderId) return;
  const query = {
    orderId,  
  };

  try {
    const response = await $fetch<{ data: OrderExtend }>('/api/order/order-one', { query });
    if (response.data) {
      store.fetchOrder(response.data);
      store.setOrder
    }
  } catch (error) {
    console.error("Failed to fetch order:", error);
  }
}
